({Component, createFactory} = require 'react') and (PropTypes = require 'prop-types')
style = require './top_content.styl'
e = require('react-e/bind') style
classNames = require 'classnames/bind'
bindStyles = classNames.bind style
moment = require('moment')

ActionItem = createFactory require './action_item'
CountCards = createFactory require './count_cards'
Flag = createFactory require './flag'
KalturaVideo = createFactory require 'components/shared/kaltura_video'
ModalWrapper = createFactory require 'components/shared/modal/modal_wrapper'

class TopContent extends Component

  @propTypes:
    classroomIsActive: PropTypes.bool
    currentUnit: PropTypes.shape(
      description: PropTypes.string.isRequired
      endDate: PropTypes.string
      image: PropTypes.string.isRequired
      name: PropTypes.string.isRequired
    ).isRequired
    dispatchRedirect: PropTypes.func.isRequired
    firstName: PropTypes.string.isRequired
    showOwl: PropTypes.bool
    teacherCards: PropTypes.arrayOf(PropTypes.any)
    topContent: PropTypes.shape(
      action: PropTypes.string
      description: PropTypes.string
      event: PropTypes.func
      hideFlag: PropTypes.bool
      image: PropTypes.string
      salutation: PropTypes.string
      video: PropTypes.string
      welcome: PropTypes.node
    )
    unitPage: PropTypes.string


  render: ->
    {classroomIsActive, currentUnit, dispatchRedirect, firstName, showOwl, teacherCards,
      topContent, unitPage} = @props
    image = topContent.image ? currentUnit.image
    salutation = topContent.salutation ? 'Welcome'

    e '.top', className: 'show-owl': showOwl,

      e '.welcome-container',
        e '.owl'
        e '.welcome',
          e 'h3.salutation', "#{salutation}, #{firstName}!"
          e '.body', topContent.welcome

      e '.card',
        Flag endDate: (currentUnit.endDate unless topContent.hideDate) unless topContent.hideFlag
        e '.hero',
          onClick: ->
            if topContent.video == 'training'
              dispatchRedirect '/training'
          style: backgroundImage: "url(#{image})", cursor: if topContent.video? then 'pointer' else 'default'
          if topContent.video?
            if topContent.video != 'training'
              ModalWrapper
                event: @props.topContent.event
                trigger: e '.play', e '.play-icon'
                KalturaVideo entryId: topContent.video
            else
              e '.play', e '.play-icon'
        if classroomIsActive
          e '.text',
            e 'h2.title', currentUnit.name
            if teacherCards? and not topContent.description?
              CountCards cards: teacherCards
            else
              e '.body',
                e 'p.description', topContent.description ? currentUnit.description
                if topContent.action?
                  ActionItem
                    actionItem: topContent
                    className: bindStyles 'action-item'
                    dispatchRedirect: dispatchRedirect
                    topContent.action
      if !classroomIsActive
        e 'h1.manual-signout', 'Before attempting to register for the upcoming school year, please SIGN OUT at the top right of this screen.  Then use your registration link to get started!'


TopContent.displayName = "TopContent"

module.exports = TopContent
